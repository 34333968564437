@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
html {
  scroll-behavior: smooth;
}
body {
  color: rgb(var(--foreground-rgb));
  background: #0f1929;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.custom-gradient {
  background: linear-gradient(to bottom, #0f19290b, #ffffff1c);
  border: 1px solid #ffffff11;
}
.cards-gradient {
  background: linear-gradient(to bottom, #0f19290b, #ffffff1c);
  border: 1px solid #ffffff11;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30;
  opacity: 0;
  transition: opacity 0.6s ease;
}
.overlay.active {
  opacity: 0.2;
}
/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffffbb; /* Scrollbar thumb color */
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #4445458c; /* Scrollbar thumb color on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #2d3748; /* Scrollbar track color */
}
.services-custom-max-h {
  max-height: calc(100vh - 100px);
}
.services-custom-min-h {
  max-height: calc(100vh - 100px);
}
/* components/AnimatedImage.module.css */
.glitch {
  position: relative;
  display: inline-block;
}

.glitch::before,
.glitch::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  mix-blend-mode: screen;
  opacity: 0.8;
  animation: glitch-animation 2s infinite;
}

.glitch::before {
  left: 2px;
  text-shadow: -2px 0 red;
  clip: rect(24px, 550px, 90px, 0);
  animation-duration: 2.5s;
}

.glitch::after {
  left: -2px;
  text-shadow: -2px 0 blue;
  clip: rect(85px, 550px, 140px, 0);
}

@keyframes glitch-animation {
  0% {
    clip: rect(44px, 9999px, 56px, 0);
    transform: skew(0.3deg);
  }
  10% {
    clip: rect(84px, 9999px, 90px, 0);
    transform: skew(0.1deg);
  }
  20% {
    clip: rect(24px, 9999px, 90px, 0);
    transform: skew(0.1deg);
  }
  30% {
    clip: rect(12px, 9999px, 67px, 0);
    transform: skew(0.2deg);
  }
  40% {
    clip: rect(54px, 9999px, 100px, 0);
    transform: skew(0.1deg);
  }
  50% {
    clip: rect(24px, 9999px, 90px, 0);
    transform: skew(0.3deg);
  }
  60% {
    clip: rect(84px, 9999px, 90px, 0);
    transform: skew(0.1deg);
  }
  70% {
    clip: rect(54px, 9999px, 90px, 0);
    transform: skew(0.2deg);
  }
  80% {
    clip: rect(44px, 9999px, 56px, 0);
    transform: skew(0.1deg);
  }
  90% {
    clip: rect(84px, 9999px, 90px, 0);
    transform: skew(0.3deg);
  }
  100% {
    clip: rect(24px, 9999px, 90px, 0);
    transform: skew(0.1deg);
  }
}
.bg-effects {
  background-image: radial-gradient(#0996c53b, #3b4143);
}
.maps div {
  border-radius: 10px;
}
.fade-in-image {
  animation: fadeIn 3s, distortion 1s ease-in-out;
  animation-delay: 1s 2s;
}
.effect--vhs:hover:before {
  margin-top: 0.2vh;
  margin-left: 0.2vh;
  opacity: 0.6;
  filter: brightness(120%);
  animation: shake-vhs 1.1s ease infinite;
}

.effect--vhs:hover:after {
  background-color: black;
  background-image: linear-gradient(transparent 30%, rgba(189, 51, 188, 0.2) 50%);
  background-size: 10px 6px;
  animation: scroll 2s ease-in-out infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes distortion {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  50% {
    transform: scaleX(1.05) scaleY(1.05);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}
/* //Browser Scrollbar Styling */

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f131; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff28; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
  border: 3px solid #ffffff1f; /* Padding around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover state for scrollbar thumb */
}

/* Industries Section  */

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  background: #0997c5;
  line-height: 10px !important;
  position: absolute;
  top: 25px !important;
  left: 0;
  width: 10px !important;
  border-radius: 20px;
  height: 10px !important;
  padding: 0px !important;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #0997c5 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 0px !important;
  padding: 0;
  cursor: pointer;
}

.mySwiper {
  padding-bottom: 60px !important;
  /* padding-right: 60px !important; */
  /* padding-left: 60px !important; */
}
.swiper-button-next,
.swiper-button-prev {
  margin: 0px 20px;
  width: 20px !important;
  height: 20px !important;
  background-color: white;
  border-radius: 50%; /* Circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff; /* White color for the arrow */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 18px; /* Adjust the size of the arrow */
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #3d4352; /* Slightly lighter on hover */
}

.swiper-button-next {
  right: 10px; /* Positioning on the right */
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-weight: bold !important;
  font-size: 10px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: black;
}
.menu-burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 23px;
  width: 30px;
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.menu-line {
  position: relative;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #13b6c581, #2d2f31);
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.menu-line::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #13b6c5b4 50%, rgba(255, 255, 255, 0) 100%);
  animation: move-glow 3s infinite linear;
}

@keyframes move-glow {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.menu-burger.open .menu-line:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.menu-burger.open .menu-line:nth-child(2) {
  opacity: 0;
}

.menu-burger.open .menu-line:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

.inputField .PhoneInputInput {
  background: #1f2937;
}
.inputField .PhoneInputInput:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.PhoneInputCountrySelect {
  background: #1f2937 !important;
}
